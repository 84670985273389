<script lang="ts" setup>
import { TheSnackbar } from '@/components';
const route = useRoute();

const showLandingBanner = computed(() => {
  return !['reset-password', 'verify', 'onboarding'].includes(route.name);
});
</script>

<template>
  <v-layout class="main-guest-layout">
    <v-main>
      <v-row justify="center">
        <v-col>
          <NuxtPage :page-key="(route) => route.fullPath" />
        </v-col>
      </v-row>
    </v-main>
    <TheSnackbar />
  </v-layout>
</template>

<style lang="scss" scoped>
.image-container {
  max-width: 42vw;
}

.v-main {
  background-color: #f6f6f6;
}
</style>
